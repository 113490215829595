import {
  createClient,
  configureChains,
} from 'wagmi'

import { publicProvider } from 'wagmi/providers/public'

const sepoliaChain: Chain = {
    id: 11155111,
    name: 'Sepolia',
    network: 'sepolia',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: {
      alchemy: { http: ['https://eth-sepolia.public.blastapi.io'] },
      infura:  { http: ['https://eth-sepolia.public.blastapi.io'] },
      default: { http: [`https://eth-sepolia.public.blastapi.io`] },
      public:  { http: [`https://eth-sepolia.public.blastapi.io`] },
    },
    blockExplorers: {
      etherscan: { name: 'Etherscan', url: 'https://sepolia.etherscan.io/' },
      default: { name: 'Etherscan', url: 'https://sepolia.etherscan.io/' },
    },
    testnet: true,
}

const networks = [sepoliaChain];

const { provider, webSocketProvider } = configureChains(networks, [publicProvider()]);

export const client = createClient({
  autoConnect: true,
  connectors: [],
  provider,
  webSocketProvider,
});
