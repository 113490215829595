import "./Main.css";
import { useMemo } from "react";
import { useContractRead } from "wagmi";

import Button from "../../components/Button/Button.js";

import { ReactComponent as OuterCircle } from "../../assets/OuterCircle.svg";
import { ReactComponent as InnerCircle } from "../../assets/InnerCircle.svg";

import TreasuryAbi from "../../abis/GhostTreasury.json";
import StakingAbi from "../../abis/GhostStaking.json";
import StinkyAbi from "../../abis/Stinky.json";

import { formatNumber } from "../../hooks/helpers";

function Main({ toggle }) {
  const { data: totalReserves } = useContractRead({
      abi: TreasuryAbi.abi,
      address: "0x2AAd1EA51044e69756880f580C13a92D910af238",
      functionName: "totalReserves",
  });

  const { data: totalStaked } = useContractRead({
      abi: StinkyAbi.abi,
      address: "0x84060da636f5a83f2668ad238f09f8c667a1ec8b",
      functionName: "circulatingSupply",
  });

  const { data: epoch } = useContractRead({
      abi: StakingAbi.abi,
      address: "0xb22Ad3b4a23EaEA8c06CD151D7C0e3758d0FB580",
      functionName: "epoch",
  });

  const apy = useMemo(() => {
      let apy = Infinity;
      if (epoch && totalStaked && !totalStaked.isZero()) {
          const value = epoch[3].div(totalStaked);
          apy = Math.pow(1 + parseFloat(value.toString()), 1095) - 1;
      }
      return apy;
  }, [epoch, totalStaked])

  return(
    <div className="main-container">
      <div className="main-container-image">
        <OuterCircle className="main-container-image-circle" />
        <InnerCircle className="main-container-image-circle-inner" />
      </div>

      <div className="main-container-buttons">
        <Button name="App" width="230px" action={() => window.open("https://app.dao.ghostchain.io", "_blank").focus()} />
        <Button name="Docs" width="230px" action={() => toggle()} />
      </div>

      <div className="main-container-stats">
        <div className="main-container-stats-unit">
          <div className="main-container-stats-unit-title">Total Staked</div>
          <div className="main-container-stats-unit-value">{totalStaked ? formatNumber(totalStaked.div(1e9).toString(), 0) : "-"}</div>
        </div>

        <div className="main-container-stats-unit">
          <div className="main-container-stats-unit-title">Treasury Balance</div>
          <div className="main-container-stats-unit-value">${totalReserves ? formatNumber(totalReserves.div(1e9).toString(), 2) : "-"}</div>
        </div>

        <div className="main-container-stats-unit">
          <div className="main-container-stats-unit-title">Current APY</div>
          <div className="main-container-stats-unit-value">{apy}%</div>
        </div>
      </div>
    </div>
  );
}

export default Main;
